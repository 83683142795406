import { RespOrder } from './type'

const BaseUrl = process.env.VUE_APP_BASE_API

export const getOrderInfo = (orderId: string): Promise<RespOrder> => fetch(`${BaseUrl}/findOrderDetails?key=${orderId}`).then((res) => res.json())
interface Result {
  returnUrl: string
  resultCode: string
  OrderNo: string
  MerOrderNo: string
}
export const getOrderResult = (orderId: string): Promise<Result> => fetch(`${BaseUrl}/findOrderStatus?key=${orderId}`).then((res) => res.json())

export const getOrderDetails = (key: string) => fetch(`${BaseUrl}/findOrderDetails?key=${key}`).then((res) => res.json())

// 校验支付方式

const headers = {
  'Content-Type': 'application/json'
}

interface ValidatePayTypeParams {
  cardNo: string,
  cardType: string
}
interface Message {
  msg: string
  status: number
}
export const validatePayType = (data: ValidatePayTypeParams): Promise<Message> => fetch(`${BaseUrl}/validatePayType`, { method: 'POST', headers, body: JSON.stringify(data) }).then(res => res.json())