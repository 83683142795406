import type { RespOrder, OrderPay } from './type'

// eslint-disable-next-line
function FormBase(url: string, data: any) {
  const form = document.createElement('form')
  const BASE_URL = process.env.VUE_APP_BASE_API
  form.action = `${BASE_URL}${url}`
  form.method = 'post'
  form.style.display = 'none'
  for (const key in data) {
    const input = document.createElement('input')
    input.name = key
    input.value = data[key]
    form.appendChild(input)
  }
  return form
}

function formPost(data: RespOrder): void {
  const form = FormBase(`/SelectToBank`, data)
  document.body.appendChild(form)
  form.submit()
}

function orderFormPost(data: OrderPay): void {
  const form = FormBase(`/confirm/bank`, data)
  document.body.appendChild(form)
  form.submit()
}
export { formPost, orderFormPost }
