import { inject, computed, ComputedRef } from 'vue'
import { RespOrder } from './type'
import { formPost } from './form'
import { isEmail, isCardNo } from '../validate'
import { FieldRule } from 'vant'

// basic-info
export interface Rules {
  [props: string]: FieldRule[]
}

interface OrderInfo {
  order: RespOrder
  payment: ComputedRef<string>
  submit: () => void
  basicRules: Rules
  addressRules: Rules
}

function getOrder(): OrderInfo {
  const order = inject<RespOrder>('orderInfo') as RespOrder
  const payment = computed(() => order.SourceAmount + order.SourceCurrency)
  function submit() {
    return formPost(order)
  }
  /* eslint-disable */
  const abankCardNo = (value: string): boolean => {
    // @ts-ignore
    return !(order.abank === 0 && value.substring(0, 2) === "34" || value.substring(0, 2) === "37") // @ts-ignore
  }
  const mbankCardNo = (value: string): boolean => {
    // @ts-ignore
    return !(order.mbank === 0 && value.substring(0, 1) === "5")
  }
  const jbankCardNo = (value: string): boolean => {
    // @ts-ignore
    return !(order.jbank === 0 && value.substring(0, 2) === "35" || value.substring(0, 4) === "1800" || value.substring(0, 4) === "2131") // @ts-ignore
  }
  const vbankCardNo = (value: string): boolean => {
    // @ts-ignore
    return !(order.vbank === 0 && value.substring(0, 1) === "4")
  }
  /* eslint-enable */
  const basicRules: Rules = {
    CardNo: [
      { required: true, message: 'Card Number cannot be empty!' },
      { validator: isCardNo, message: 'Incorrect card number!' },
      { validator: abankCardNo, message: 'AE is not available,please use other card to pay!' },
      { validator: mbankCardNo, message: 'Mastcard is not available,please use other card to pay!' },
      { validator: jbankCardNo, message: 'JCB is not available,please use other card to pay!' },
      { validator: vbankCardNo, message: 'Visa is not available,please use other card to pay!' }
    ],
    Cvv2: [{ required: true, message: 'Cvv2 is empty!' }],
    FirstName: [{ required: true, message: 'First Name cannot be empty!' }],
    LastName: [{ required: true, message: 'Last Name cannot be empty!' }]
  }
  const addressRules: Rules = {
    Country: [{ required: true, message: 'Country cannot be empty!' }],
    Address: [{ required: true, message: 'Address cannot be empty!' }],
    City: [{ required: true, message: 'City cannot be empty!' }],
    State: [{ required: true, message: 'State cannot be empty!' }],
    Zip: [{ required: true, message: 'Zip cannot empty!' }],
    Email: [
      { required: true, message: 'Email cannot be empty!' },
      { validator: isEmail, message: 'Incorrect email!' }
    ],
    Phone: [{ required: true, message: 'Phone cannot be empty!' }]
  }
  return { order, payment, submit, basicRules, addressRules }
}

function toWhat(): void {
  window.open('http://127.0.0.1:5501/cvv_demo.html')
}

export { getOrder, toWhat }
